<template>
  <v-container fluid>
    <v-card raised v-if="showScreen">
      <loading :active.sync="loading" />

      <v-flex>
        <NotificationList
          :loader="loading"
          :reset="reset"
          v-on:reset-done="resetDone"
        />
      </v-flex>
    </v-card>
  </v-container>
</template>

<script>
import Swal from "sweetalert2";
import NotificationList from "./NotificationList";
import moment from "moment";
import Loading from "vue-loading-overlay";
export default {
  created() {
    //  this.checkPermission("request-callback");
    this.$eventBus.$on("on-load", () => {
      this.loading = true;
    });

    this.$eventBus.$on("off-load", () => {
      this.loading = false;
    });
  },
  components: {
    Loading,
    NotificationList,
  },

  data: () => ({
    dashboard: {
      closeRequest: 0,
      openRequest: 0,
      totalData: 0,
    },
    loading: false,
    showScreen: true,
    filterData: [],
    filtertype: "",
    filter: "",
    search: "",
    startDate: "",
    endDate: "",
    dialog: false,
    reset: false,
  }),
  methods: {
    checkPermission(operation) {
      const { serverUrl, rbacUrl, apiUrl } = this.constants;
      this.$http
        .post(`${rbacUrl}/checkUserPermission`, {
          userId: localStorage.getItem("userId"),
        })
        .then((res) => {
          if (!res.data.data) {
            this.loading = false;
          }
          let old = res.data.data.roles;
          old = old.map((x) => x.role.roleName.toLowerCase());

          let roles = JSON.parse(localStorage.getItem("permissions")).roles;
          roles = roles.map((x) => x.role.roleName.toLowerCase());

          old.sort();
          roles.sort();

          if (old.toString() != roles.toString()) {
            this.showScreen = false;
            localStorage.setItem("permissions", JSON.stringify(res.data.data));
            Swal.fire({
              title: "Oops!",
              text: "Your permissions have changed .",
              type: "error",
              allowOutsideClick: false,
              allowEscapeKey: false,
              confirmButtonColor: "orange",
              confirmButtonText: "Okay",
            }).then((result) => {
              localStorage.setItem("first-load", true);
              this.$router.replace("/");
              location.reload();
            });
          }
          if (old.toString() == roles.toString()) {
            localStorage.setItem("permissions", JSON.stringify(res.data.data));
          }
        })
        .catch((e) => {
          this.loading = false;
        });
    },

    resetDone() {
      this.loading = false;
    },
  },
};
</script>

<style></style>
