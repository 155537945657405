<template>
  <div>
    <v-card class="nospace">
      <v-card-text class="nospace">
        <v-flex xs12 style="text-align: end">
          <v-btn
            class="ma-2"
            outline
            color="orange"
            :disabled="unreadCount == 0 || !unreadCount"
            @click="markAllRead()"
          >
            Mark all as read
          </v-btn>
        </v-flex>
        <v-data-table
          id="NotificationListTable"
          hide-actions
          :headers="headers"
          :items="items"
        >
          <template slot="items" slot-scope="props">
            <tr>
              <td
                :class="!props.item.isRead ? 'dark-color' : ''"
                @click="markRead(props.item)"
              >
                <label v-if="!props.item.isRead" class="__dot"></label>
                <div
                  v-if="props.item.type == 1"
                  @click="checkViewJob(props.item.jobData.jobId)"
                  target="_blank"
                  class="anchor"
                >
                  <p
                    class="gray-dark marr-5"
                    style="text-align: left !important"
                  >
                    {{ props.item.message }}
                  </p>
                  <p class="tag-gray d-flex marr-5">
                    <span
                      style="text-align: left !important"
                      v-if="props.item.senderdData"
                      >Added by: {{ props.item.senderdData.name }}</span
                    >
                    <span class="text-right">{{
                      getUtcTime(props.item.createdAt)
                    }}</span>
                  </p>
                </div>
                <div
                  v-else-if="props.item.type == 4"
                  @click="checkTender(props.item.jobData.jobModel)"
                  class="anchor"
                >
                  <p
                    class="gray-dark marr-5"
                    style="text-align: left !important"
                  >
                    {{ props.item.message }}
                  </p>

                  <p class="tag-gray d-flex marr-5">
                    <span class="text-right">{{
                      getUtcTime(props.item.createdAt)
                    }}</span>
                  </p>
                </div>
                <div
                  v-else-if="props.item.type == 3"
                  @click="redirectApproval('sales')"
                  class="anchor"
                >
                  <p
                    class="gray-dark marr-5"
                    style="text-align: left !important"
                  >
                    {{ props.item.message }}
                  </p>

                  <p class="tag-gray d-flex marr-5">
                    <span class="text-right">{{
                      getUtcTime(props.item.createdAt)
                    }}</span>
                  </p>
                </div>
                <div
                  v-else-if="props.item.type == 2"
                  @click="redirectApproval('job')"
                  class="anchor"
                >
                  <p
                    class="gray-dark marr-5"
                    style="text-align: left !important"
                  >
                    {{ props.item.message }}
                  </p>

                  <p class="tag-gray d-flex marr-5">
                    <span class="text-right">{{
                      getUtcTime(props.item.createdAt)
                    }}</span>
                  </p>
                </div>
                <div
                  v-else-if="props.item.type == 8"
                  @click="redirectNotification('procurements')"
                  class="anchor"
                >
                  <p
                    class="gray-dark marr-5"
                    style="text-align: left !important"
                  >
                    {{ props.item.message }}
                  </p>

                  <p class="tag-gray d-flex marr-5">
                    <span class="text-right">{{
                      getUtcTime(props.item.createdAt)
                    }}</span>
                  </p>
                </div>
                <div
                  v-else-if="props.item.type == 32"
                  @click="
                    redirectAccountsDriver(
                      props.item.assignmentIdNumber,
                      props.item.workingCountry
                    )
                  "
                  class="anchor"
                >
                  <p
                    class="gray-dark marr-5"
                    style="text-align: left !important"
                  >
                    {{ props.item.message }}
                  </p>

                  <p class="tag-gray d-flex marr-5">
                    <span class="text-right">{{
                      getUtcTime(props.item.createdAt)
                    }}</span>
                  </p>
                </div>
                <div
                  v-else-if="props.item.type == 9"
                  @click="redirectNotification('request-callback')"
                  class="anchor"
                >
                  <p
                    class="gray-dark marr-5"
                    style="text-align: left !important"
                  >
                    {{ props.item.message }}
                  </p>

                  <p class="tag-gray d-flex marr-5">
                    <span class="text-right">{{
                      getUtcTime(props.item.createdAt)
                    }}</span>
                  </p>
                </div>
                <div
                  v-else-if="props.item.type == 7"
                  @click="
                    redirectTransDriver(
                      props.item.transporterData.transporterId,
                      props.item.transporterData._id,
                      props.item.workingCountry
                    )
                  "
                  class="anchor"
                >
                  <p
                    class="gray-dark marr-5"
                    style="text-align: left !important"
                  >
                    {{ props.item.message }}
                  </p>

                  <p class="tag-gray d-flex marr-5">
                    <span class="text-right">{{
                      getUtcTime(props.item.createdAt)
                    }}</span>
                  </p>
                </div>
                <div
                  v-else-if="props.item.type == 10"
                  @click="redirectjobDetail(props.item.jobData.jobId)"
                  class="anchor"
                >
                  <p
                    class="gray-dark marr-5"
                    style="text-align: left !important"
                  >
                    {{ props.item.message }}
                  </p>

                  <p class="tag-gray d-flex marr-5">
                    <span class="text-right">{{
                      getUtcTime(props.item.createdAt)
                    }}</span>
                  </p>
                </div>
                <div
                  v-else-if="props.item.type == 11"
                  @click="redirectNotification('transporter')"
                  class="anchor"
                >
                  <p
                    class="gray-dark marr-5"
                    style="text-align: left !important"
                  >
                    {{ props.item.message }}
                  </p>

                  <p class="tag-gray d-flex marr-5">
                    <span class="text-right">{{
                      getUtcTime(props.item.createdAt)
                    }}</span>
                  </p>
                </div>
                <div
                  v-else-if="props.item.type == 12"
                  @click="redirectAccounts2()"
                  class="anchor"
                >
                  <p
                    class="gray-dark marr-5"
                    style="text-align: left !important"
                  >
                    {{ props.item.message }}
                  </p>

                  <p class="tag-gray d-flex marr-5">
                    <span class="text-right">{{
                      getUtcTime(props.item.createdAt)
                    }}</span>
                  </p>
                </div>
                <div
                  v-else-if="props.item.type == 13"
                  @click="redirectAccounts2()"
                  class="anchor"
                >
                  <p
                    class="gray-dark marr-5"
                    style="text-align: left !important"
                  >
                    {{ props.item.message }}
                  </p>

                  <p class="tag-gray d-flex marr-5">
                    <span class="text-right">{{
                      getUtcTime(props.item.createdAt)
                    }}</span>
                  </p>
                </div>
                <div
                  v-else-if="props.item.type == 17"
                  @click="redirectAccounts()"
                  class="anchor"
                >
                  <p
                    class="gray-dark marr-5"
                    style="text-align: left !important"
                  >
                    {{ props.item.message }}
                  </p>

                  <p class="tag-gray d-flex marr-5">
                    <span class="text-right">{{
                      getUtcTime(props.item.createdAt)
                    }}</span>
                  </p>
                </div>
                <div
                  v-else-if="props.item.type == 18"
                  @click="redirectCustomers()"
                  class="anchor"
                >
                  <p
                    class="gray-dark marr-5"
                    style="text-align: left !important"
                  >
                    {{ props.item.message }}
                  </p>

                  <p class="tag-gray d-flex marr-5">
                    <span class="text-right">{{
                      getUtcTime(props.item.createdAt)
                    }}</span>
                  </p>
                </div>
                <div
                  v-else-if="props.item.type == 14"
                  @click="
                    redirectAccounts2();
                    selectActive(39);
                  "
                  class="anchor"
                >
                  <p
                    class="gray-dark marr-5"
                    style="text-align: left !important"
                  >
                    {{ props.item.message }}
                  </p>

                  <p class="tag-gray d-flex marr-5">
                    <span class="text-right">{{
                      getUtcTime(props.item.createdAt)
                    }}</span>
                  </p>
                </div>
                <div
                  v-else-if="props.item.type == 19"
                  @click="redirectNotification('sales')"
                  class="anchor"
                >
                  <p
                    class="gray-dark marr-5"
                    style="text-align: left !important"
                  >
                    {{ props.item.message }}
                  </p>

                  <p class="tag-gray d-flex marr-5">
                    <span class="text-right">{{
                      getUtcTime(props.item.createdAt)
                    }}</span>
                  </p>
                </div>
                <div
                  v-else-if="props.item.type == 21"
                  @click="redirectNotification('customers')"
                  class="anchor"
                >
                  <p
                    class="gray-dark marr-5"
                    style="text-align: left !important"
                  >
                    {{ props.item.message }}
                  </p>

                  <p class="tag-gray d-flex marr-5">
                    <span class="text-right">{{
                      getUtcTime(props.item.createdAt)
                    }}</span>
                  </p>
                </div>
                <div
                  v-else-if="props.item.type == 23"
                  @click="redirectNotification('drivers')"
                  class="anchor"
                >
                  <p
                    class="gray-dark marr-5"
                    style="text-align: left !important"
                  >
                    {{ props.item.message }}
                  </p>

                  <p class="tag-gray d-flex marr-5">
                    <span class="text-right">{{
                      getUtcTime(props.item.createdAt)
                    }}</span>
                  </p>
                </div>
                <div
                  v-else-if="props.item.type == 24"
                  @click="redirectjobs()"
                  class="anchor"
                >
                  <p
                    class="gray-dark marr-5"
                    style="text-align: left !important"
                  >
                    {{ props.item.message }}
                  </p>

                  <p class="tag-gray d-flex marr-5">
                    <span class="text-right">{{
                      getUtcTime(props.item.createdAt)
                    }}</span>
                  </p>
                </div>
                <div
                  v-else-if="props.item.type == 25"
                  @click="redirectAccounts2('drivers')"
                  class="anchor"
                >
                  <p
                    class="gray-dark marr-5"
                    style="text-align: left !important"
                  >
                    {{ props.item.message }}
                  </p>

                  <p class="tag-gray d-flex marr-5">
                    <span class="text-right">{{
                      getUtcTime(props.item.createdAt)
                    }}</span>
                  </p>
                </div>
                <div
                  v-else-if="props.item.type == 26"
                  @click="redirectAccounts2('drivers')"
                  class="anchor"
                >
                  <p
                    class="gray-dark marr-5"
                    style="text-align: left !important"
                  >
                    {{ props.item.message }}
                  </p>

                  <p class="tag-gray d-flex marr-5">
                    <span class="text-right">{{
                      getUtcTime(props.item.createdAt)
                    }}</span>
                  </p>
                </div>
                <div
                  v-else-if="props.item.type == 27"
                  @click="redirectAccounts2()"
                  class="anchor"
                >
                  <p
                    class="gray-dark marr-5"
                    style="text-align: left !important"
                  >
                    {{ props.item.message }}
                  </p>

                  <p class="tag-gray d-flex marr-5">
                    <span class="text-right">{{
                      getUtcTime(props.item.createdAt)
                    }}</span>
                  </p>
                </div>
                <div
                  v-else-if="props.item.type == 28"
                  @click="redirectjobs()"
                  class="anchor"
                >
                  <p
                    class="gray-dark marr-5"
                    style="text-align: left !important"
                  >
                    {{ props.item.message }}
                  </p>

                  <p class="tag-gray d-flex marr-5">
                    <span class="text-right">{{
                      getUtcTime(props.item.createdAt)
                    }}</span>
                  </p>
                </div>
                <div
                  v-else-if="props.item.type == 29"
                  @click="redirectAccounts2()"
                  class="anchor"
                >
                  <p
                    class="gray-dark marr-5"
                    style="text-align: left !important"
                  >
                    {{ props.item.message }}
                  </p>

                  <p class="tag-gray d-flex marr-5">
                    <span class="text-right">{{
                      getUtcTime(props.item.createdAt)
                    }}</span>
                  </p>
                </div>
                <div
                  v-else-if="props.item.type == 30"
                  @click="redirectProof(props.item.assignmentId)"
                  class="anchor"
                >
                  <p
                    class="gray-dark marr-5"
                    style="text-align: left !important"
                  >
                    {{ props.item.message }}
                  </p>

                  <p class="tag-gray d-flex marr-5">
                    <span class="text-right">{{
                      getUtcTime(props.item.createdAt)
                    }}</span>
                  </p>
                </div>
                <div class="" v-else>
                  <p
                    class="gray-dark marr-5"
                    style="text-align: left !important"
                  >
                    {{ props.item.message }}
                  </p>

                  <p class="tag-gray d-flex marr-5">
                    <v-avatar
                      v-if="props.item.isRead"
                      style="
                        position: absolute;
                        top: 0 px !important;
                        right: 0 px;
                        top: 5px;
                        right: 115px;
                        z-index: 7;
                      "
                      color="orange"
                      size="22"
                    >
                    </v-avatar>
                    <span class="text-right">{{
                      getUtcTime(props.item.createdAt)
                    }}</span>
                  </p>
                </div>
              </td>
              <!-- <td >
              {{getType( props.item.type) }}
            </td>-->
            </tr>
          </template>
          <v-alert
            slot="no-results"
            :value="isNoSearchResult"
            color="blue"
            icon="warning"
            >Your search for "{{ search }}" found no results.</v-alert
          >
        </v-data-table>
        <!-- <span class="page-count-span totalpages"
          >Total Pages - {{ totalPages }}</span
        > -->
        <!-- <PaginationButtons
          :url="notificationList"
          :payload="payload"
          :callback="callback"
          :key="componentKey"
        /> -->

        <pagination
          :totalPages="totalPages"
          :pages="this.pages"
          @pageNo="page"
          :url="notificationList"
          :callback="callback"
          :componentKey="componentKey"
          :action="`notification`"
          :payload="payload"
          :type="1"
          v-on:pagination-load="checkLoading"
        />
      </v-card-text>
    </v-card>

    <ErrorBox :error="x.error" />
  </div>
</template>

<script>
import _ from "lodash";
import { EventBus } from "../../event-bus.js";
import moment from "moment";
import PaginationButtons from "@/components/Pagination/Pagination-New";
import { notificationList } from "@/constants/api-urls.js";
import vueCustomScrollbar from "vue-custom-scrollbar";
import SuccessDialog from "@/components/Common/SuccessDialog";
import ErrorBox from "@/components/Common/ErrorBox";
import { debuglog } from "util";
import { StorageKeys } from "../../constants/constants";
import pagination from "@/components/Pagination/pagination";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import "moment/locale/es"; // without this line it didn't work
moment.locale("en-in");

export default {
  name: "requestList",
  created() {
    // this.commentsList();
    this.getUnreadNotificationCount();
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      let workingCountry = [];
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          workingCountry.push(key.value);
        }
      });
      this.workingCountry = JSON.stringify(workingCountry);
    }

    EventBus.$on("countryChanged", (val) => {
      this.workingCountry = JSON.stringify(val);
      this.loading = true;
      this.payload = {
        workingCountry: this.workingCountry,
      };
      this.$eventBus.$emit("on-load");
    });

    this.payload = {
      workingCountry: this.workingCountry,
    };

    this.$eventBus.$on("close-job-profile", () => {
      this.dialog = false;
      this.currentItem = null;
    });
    this.$eventBus.$on("notify", (val) => {
      if (this.$route.name == "notifications") {
        this.pages = 1;
        this.componentKey = this.componentKey + 1;
        this.getUnreadNotificationCount();
      }
    });
  },
  components: {
    pagination,
    SuccessDialog,
    ErrorBox,
    vueCustomScrollbar,
  },
  props: {
    reset: Boolean,
  },
  watch: {
    unreadCount(e) {
      this.unreadCount = e;
    },
    reset: function (n, o) {
      this.loading = true;
      if (n == true) {
        this.payload = {
          workingCountry: this.workingCountry,
        };
      }
    },

    deep: true,
    immediate: true,
  },
  data() {
    return {
      notificationList: notificationList,
      workingCountry: null,
      operationName: "",
      unreadCount: 0,
      componentKey: 0,
      search: "",
      isNoSearchResult: false,
      loading: true,
      payload: {},
      totalPages: 0,
      pages: 1,
      x: {
        message: "",
        loading: false,
        success: false,
        menu: false,
        dateOfBirth: null,
        error: null,
        missingField: null,
      },
      settings: {
        suppressScrollY: false,
        suppressScrollX: false,
        wheelPropagation: true,
      },
      headers: [
        {
          text: "Notifications",
          sortable: false,
          value: "message",
          class: "table-header-item",
        },
      ],
      items: [],

      totalData: 0,
    };
  },
  methods: {
    redirectProof(id) {
      this.$router.push(`/documents/${id}?type=Driver_Proof`);
    },
    redirectAccounts2() {
      console.log("redirectt advance");
      this.$router.push(`/accounts/advance`).catch(() => {});
    },
    redirectjobs() {
      this.$router.push(`/assignments`);
    },
    checkLoading(event) {
      if (event) {
        this.$eventBus.$emit("on-load");
      } else {
        this.$eventBus.$emit("off-load");
      }
    },
    page(event) {
      this.pages = event;
    },
    async checkViewJob(jobId) {
      this.operationName = "view-details-jobs";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.$eventBus.$emit("view-details", true);
        this.$router.push(`/jobs/${jobId.toString()}/view`);
        this.$eventBus.$emit("refresh-job");
      } else {
        return;
      }
    },
    async getUnreadNotificationCount() {
      if (localStorage.getItem("workingCountries")) {
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        let workingCountry = [];
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            workingCountry.push(key.value);
          }
        });
        this.workingCountry = JSON.stringify(workingCountry);
      }
      let url = `/commentRoute/v1/getNotificationCount?workingCountry=${this.workingCountry}`;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      const response = await this.axios.get(
        this.constants.apiUrl + url,
        config
      );
      if (response.status === 200) {
        this.unreadCount = response.data.data.totalCount;
        localStorage.setItem("unreadCount", this.unreadCount);
      } else {
        this.loading = false;
      }
    },
    redirectApproval(type) {
      this.$router.push(`/approvals?type=${type}`);
    },
    redirectCustomers() {
      this.$router.push(`/customers?type=${"inactive"}`);
    },
    redirectAccounts() {
      this.$router.push(`/accounts`);
    },
    redirectjobDetail(jobId) {
      this.$router.push(`/jobs/${jobId}`);
    },
    redirectTransDriver(transporterId, _id, workingCountry) {
      this.$router.push(
        `/transporter/driver/${transporterId}/${_id}/${workingCountry}`
      );
    },
    async checkTender(model) {
      this.$router.push(`/tender/${model}`);
    },
    redirectNotification(type) {
      this.$router.push(`/${type}`);
    },
    markAllRead() {
      let url = `/commentRoute/v1/readNotification`;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };

      if (localStorage.getItem("workingCountries")) {
        this.workingCountry = [];
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            this.workingCountry.push(key.value);
          }
        });
      }

      let body = {
        workingCountry: this.workingCountry,
        isReadAll: true,
      };

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          if (response.data.data) {
            this.$eventBus.$emit("unread-notify-count");
            this.componentKey = this.componentKey + 1;
            this.getUnreadNotificationCount();
          }
        },
        (error) => {
          this.error = error.response.data.message;
        }
      );
    },
    markRead(data) {
      let url = `/commentRoute/v1/readNotification`;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };

      if (localStorage.getItem("workingCountries")) {
        this.workingCountry = [];
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            this.workingCountry.push(key.value);
          }
        });
      }

      let body = {
        workingCountry: this.workingCountry,

        _id: data._id,
        isReadAll: false,
      };
      if (this.btnValue) {
      }

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          if (response.data.data) {
            this.$eventBus.$emit("unread-notify-count");
            this.getUnreadNotificationCount();
            this.componentKey = this.componentKey + 1;
          }
        },
        (error) => {
          this.error = error.response.data.message;
        }
      );
    },

    commentsList() {
      this.items = [];
      let url = "/commentRoute/getNotificationList";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      this.workingCountry = [];
      if (localStorage.getItem("workingCountries")) {
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            this.workingCountry.push(key.value);
          }
        });
      }
      let body = {
        workingCountry: this.workingCountry,
        pageNo: "1",
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          if (response.data.data && response.data.data.list.length) {
            this.items = response.data.data.list;
            this.$emit("reset-done");
            this.totalData = response.data.data.totalData;
          } else {
          }
        },
        (error) => {
          this.error = error.response.data.message;
        }
      );
    },
    getUtcTime(date) {
      return moment(date).format("ll LT");
    },

    redirectAccountsDriver(assignmentId, workingCountry) {
      this.$router
        .push(
          `/accounts/advance?type=${assignmentId}&country=${workingCountry}`
        )
        .catch(() => {});
    },

    getTime(date) {
      return moment(date).format("ll");
    },
    callback(res) {
      this.items = res.list;
      this.totalPages = Math.ceil(res.totalData / 15);
      this.$emit("reset-done");
      window.scrollTo(0, 0);
    },
    getType(data) {
      if (data == 1) {
        return "Comments";
      } else if (data == 2) {
        return "Job Approval";
      } else if (data == 3) {
        return "Inquiry Approval";
      } else if (data == 4) {
        return "Bid Accept";
      } else {
        return "-";
      }
    },
  },
};
</script>

<style lang="scss">
.text-right {
  text-align: right;
}

.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

td {
  font-size: 16px !important;
}

thead {
  background: #fbfbfb;
}
</style>
<style scoped>
li.btn {
  display: block;
}
ul {
  padding: 0;
}
.request-callback-actions {
  padding: 0;
  margin: auto 20px auto 0px;
  cursor: pointer;
}
.requestCallback {
  margin: auto 0;
}
.page-count-span {
  float: right;
  margin-top: 26px;
  color: darkorange;
}
.assign-driver-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #bebebe;
  border: 2px solid grey;
  padding: 2px 5px;
  border-radius: 5px;
}
.chat-history {
  height: 600px;
  padding: 4px;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  overflow-x: hidden;
}
.v-card__title.bg-grey {
  background: #f5f5f5 !important;
  border-bottom: 1px solid #e6e6e6;
}
.bg-gray {
  background: #ebebeb;
}

.tag-people {
  height: 150px;
  overflow: hidden;
}
.send-layout {
  background: #ffff;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 5px 0px 5px 5px;
  min-width: 320px;
  max-width: 320px;
  float: right;
  margin-right: 11px;
}
.received-layout {
  background: #ffff;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 0px 5px 5px 5px;
  min-width: 320px;
  max-width: 320px;
  float: left;
}
.flex-tag {
  overflow-y: auto;
  max-height: 100px;
}
.tag-orange {
  color: #ff8c00;
  font-size: 13px;
}
.tag-gray {
  color: #b1abab;
  font-size: 12px;
}
.gray-dark {
  color: #4d4d4d;
}
.chat-history .row p {
  margin-bottom: 5px;
}
.anchor {
  cursor: pointer;
}
.marr-5 {
  margin-bottom: 5px;
}
label.__dot {
  width: 8px;
  height: 8px;

  background: #f47824;
  border-radius: 50px;
  position: absolute;
  right: 15px;
}

.dark-color {
  background-color: #fef1e9;
}
.text-right {
  text-align: right;
}
</style>
